export default function() {
	var container = $(".has-toggle");

	if(container) {
		var opener = container.find(".billing-form-opener");
		var slide = container.find('#' + opener.attr("data-for"));

		if(slide) {
			slide.hide();
			opener.on("click", function() {
				slide.slideToggle();
				opener.toggleClass("active");
			})
		}
	}
}