import header from '../modules/header/index';
import basketResult from "../modules/basketResult";
import productCarousel from "../modules/productCarousel";
import addToCardEventInit from "../modules/addToCardEvent";

jQuery(function() {
    header();
    basketResult();
    productCarousel();
    addToCardEventInit();
})